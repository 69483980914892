<template>
    <div class="notices flex">
        <div class="page-header flex-wrap">
            <StackRouterHeaderBar />
            <div class="title" v-html="$translate('NOTICES')" />
        </div>
        <div class="notice-list flex-fill">
            <div
                @click="onClickNotice(notice)"
                class="notice flex-row items-center flex-between flex-wrap"
                :key="notice.id"
                v-for="notice in list"
            >
                <div class="flex-fill">
                    <div class="flex-row items-center">
                        <div class="timestamp flex-wrap m-r-8">{{ notice.created_at | formatDate('YY.MM.DD') }}</div>
                        <div class="badges flex-row">
                            <div
                                v-if="notice.is_required"
                                class="badge badge-brd red flex-wrap"
                                v-html="$translate('NOTICE_REQUIRED')"
                            />
                            <div v-if="notice.is_new" class="badge badge-brd primary flex-wrap" v-html="'NEW'" />
                        </div>
                    </div>
                    <div class="title" v-html="notice.title" />
                </div>
                <i class="material-icons flex-wrap">chevron_right</i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NoticesPage',
    computed: {
        list() {
            return this.$store.getters.notices
        },
    },
    methods: {
        onClickNotice(notice) {
            this.$modal.custom({
                component: 'ModalNotice',
                options: {
                    notice,
                },
            })
        },
    },
}
</script>
<style scoped lang="scss">
.page-header {
    ::v-deep .stack-router-header-bar {
        padding: 0 !important;
    }
}
</style>
